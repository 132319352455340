<template>
  <div>
    <Header />
    <Loading v-if="isLoading" />
    <div class="logo w-1920">
      <div class="logopic w-1200">
        <div class="logopic-left">
          <img @click="home" src="../../assets/login/logo.gif" alt="" />
          <div class="zhuce">忘记密码</div>
        </div>
      </div>
    </div>
    <div class="conter w-1920">
      <div class="root">
        <el-form
          :model="Register"
          ref="RegisterForm"
          label-width="0"
          class="RegisterForm"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="Register.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="Register.pass"
              placeholder="请输入密码"
              type="password"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="Register.checkPass"
              placeholder="请再次输入密码"
              type="password"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="验证码" class="code">
            <el-input
              v-model="Register.sendcode"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              type="button"
              @click="sendcode"
              :disabled="disabled"
              v-if="disabled == false"
              >发送验证码
            </el-button>
            <el-button
              type="button"
              @click="sendcode"
              :disabled="disabled"
              v-if="disabled == true"
              >{{ btntxt }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Login_bottom />
    <el-button :plain="true" v-show="false"></el-button>
  </div>
</template>
<script>
import { get, post } from '@/utils/request';
import Header from '@/components/header.vue';
import Login_bottom from '@/components/login/login_bottom.vue';
import slider from '@/components/login/verify.vue';
export default {
  components: {
    Login_bottom,
    Header,
    slider,
  },
  data() {
    return {
      Register: {
        phone: '',
        sendcode: '',
        pass: '',
        checkPass: '',
      },
      disabled: false,
      time: 0,
      btntxt: '重新发送',

      input3: '',
      select: '',
      myForm: {},
      isLoading: false,
    };
  },
  methods: {
    home() {
      this.$router.push({
        name: 'Home',
      });
    },
    //手机验证发送验证码
    sendcode() {
      this.isLoading = true;
      const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.Register.phone == '') {
        this.isLoading = false;
        this.$message.warning({
          message: '手机号不能为空',
        });
        return;
      }
      if (!reg.test(this.Register.phone)) {
        this.isLoading = false;
        this.$message.warning({
          message: '请输入正确的手机号',
        });
        return;
      } else {
        get(
          'f/api/register/updatePasswordPhone?phone=' + this.Register.phone
        ).then((res) => {
          this.isLoading = false;
          if (res.data.msg == '短信发送成功') {
            this.$message.success({
              message: '发送成功',
            });
            this.time = 60;
            this.disabled = true;
            this.timer();
          } else if (res.data.msg == '账号不存在') {
            this.$message.warning({
              message: '账号不存在',
            });
          } else {
            this.$message.warning({
              message: '发送失败',
            });
          }
        });
      }
    },
    //60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + 's后重新获取';
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = '获取验证码';
        this.disabled = false;
      }
    },
    submitForm() {
      this.isLoading = true;
      const reg1 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
      if (!reg1.test(this.Register.pass)) {
        this.isLoading = false;
        this.$message.warning({
          message: '密码须包含数字、字母两种元素，且密码位数为6-16位',
        });
        return;
      } else if (
        this.Register.phone &&
        this.Register.sendcode &&
        this.Register.pass &&
        this.Register.checkPass
      ) {
        if (this.Register.pass != this.Register.checkPass) {
          this.isLoading = false;
          this.$message.warning({
            message: '两次密码不一致',
          });
        } else {
          post('f/api/register/updatePassword', {
            phone: this.Register.phone,
            code: this.Register.sendcode,
            password: this.Register.pass,
          }).then((res) => {
            this.isLoading = false;
            if (res.data.msg == '操作成功') {
              this.$message.success({
                message: '修改成功',
              });
              this.$router.push({
                name: 'Login',
              });
            } else {
              this.$message.error({
                message: '修改失败',
              });
            }
          });
        }
      } else {
        this.isLoading = false;
        this.$message.warning({
          message: '请填写完整',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conter {
  width: 100%;
  height: 128px;
  margin-top: 160px;
  .root {
    background: url('http://img.hena360.cn/login_bj.jpg') no-repeat center;
    background-size: 100% 100%;
    height: 60px;
    width: 440px;
    margin: auto;

    form {
      width: 90%;
      margin-top: 20px;
      margin: auto;
      .code {
        .el-input {
          width: 65%;
          border-radius: 0px;
          float: left;
          display: inline-block;
        }
        .el-button {
          width: 35%;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px;
          float: left;
          display: inline-block;
        }
      }
      .el-button--success {
        width: 100%;
        background-color: #245af2;
        border: none;
        color: #fff;
      }
    }
  }
}

.form-wrapper {
  margin-top: 50px;
  width: 500px;
  margin-left: 0;
}
.logo {
  width: 100%;
  height: 110px;
  border-bottom: 3px solid #f5f5f5;
  .logopic {
    height: 100%;
    margin: 0 auto;
    display: flex;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 158px;
        height: 44px;
        margin-left: 33px;
        cursor: pointer;
      }
      .zhuce {
        font-size: 20px;
        color: #245af2;
        font-weight: 600;
        margin-left: 27px;
      }
    }
    &-right {
      height: 100px;
      font-size: 15px;
      display: flex;
      align-items: flex-end;
      &_L {
        color: #9a9a9a;
      }
      &_R {
        color: #f14f44;
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }
}
.conter {
  padding-bottom: 400px;
  width: 100%;
  .buzhou {
    width: 612px;
    margin: auto;
    margin-top: 50px;
    .logo-search-progress {
      width: 100%;
      height: 104px;
    }
  }
  .input {
    width: 612px;
    height: 100%;
    margin: auto;
    margin-top: 90px;
    display: flex;
    .el-select {
      width: 250px;
    }
  }
  .yanzheng {
    width: 612px;
    margin: auto;
    text-align: center;
    cursor: pointer;
  }
}
</style>
